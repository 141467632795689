<template>
  <div>
      <v-container fluid class="pa-0 mt-0">
         <v-card class="mx-5 my-5 custom-card">
      <v-row class="ma-0 pa-4 align-center lh-80">
        <v-col cols="2" class="pa-0"
        ><h3 width="100" height="24" class=".text-lg-h6">Employee</h3></v-col
      >
      <v-col class="pa-0" cols="1"></v-col>
      <v-col cols="6" class="pa-0">
        <v-text-field
          v-model="search"
          single-line
          dense
          outlined
          prepend-inner-icon="mdi-magnify"
          placeholder="Search"
          hide-details=true
        ></v-text-field>
      </v-col>
      <v-col class="pa-0" cols="1"></v-col>
    </v-row>
</v-card>
    <v-data-table :headers="headers" v-bind:items="this.employeRecord" :search="search" :value="this.employeRecord" item-key="name" class="elevation-1 mx-5 mb-5" id="table"
      >
      <template slot="this.employeedata" slot-scope="">
        <tr>
          <th
             v-for="(item, index) in this.employeRecord"
            :key="index"
            class="tableRowHeight"
          ></th>
        </tr>
      </template>
      <template v-slot:item="props">
        <tr>
          <td>{{ props.index + 1 }}</td>
          <td>{{ props.item.emp_id }}</td>
          <td>{{ props.item.emp_name }}</td>
          <td>{{ props.item.emp_email }}</td>
        </tr>
      </template>
    </v-data-table>
      </v-container>
  </div>
</template>

 <script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      search: "",
      name: "",
      userid: "",
      i: "",
      customerType: "",
      searchQuery: null,
      headers: [
        { text: "S.NO", align: "start", sortable: true, value: "emp_id" },
        { text: "Emp ID", value: "emp_id ", sortable: true },
        { text: "Emp Name", value: "emp_name", sortable: true },
        { text: "Emp Email", value: "emp_email", sortable: true },
      ],
    };
  },



  computed: {
    ...mapState(["employeRecord"]),
  },

  mounted() {
    this.$store.dispatch("getEmployeeData");
  },

  methods: {
    nextpage() {
      this.$router.push("/createcustomer");
    },
  }
};
</script>

<style>
.tableRowHeight {
  height: 56px !important;
}
::v-deep .v-text-field__details {
  display: none !important;
}
::v-deep .v-input__slot {
  margin: 0 !important;
}
.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  font-size: inherit;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 40px;
}
.fontfamily {
  font-family: "Lato";
}
.v-data-table ::v-deep th {
  font-size: 14px !important;
  color: black !important;
}
::v-deep .v-data-table__empty-wrapper {
  display: none !important;
}
.v-data-table {
    border-radius: 12px !important;
}
.custom-card.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%), 0px 1px 5px 0px rgb(0 0 0 / 5%) !important;
}

</style>